<template>
  <div>
    <BaseLoading :loading="loading" />

    <Navbar v-if="!isAdmin">
      <span> Maldives </span>
    </Navbar>

    <v-main :class="isAdmin && 'container'">
      <v-container v-if="isAdmin">
        <v-row justify="space-between">
          <v-col cols="10">
            <v-form ref="form">
              <v-text-field
                v-model="searchInviteCode"
                counter
                label="Invite Code"
                :rules="rules"
                @keydown.enter.prevent="() => {}"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="2" class="d-flex justfy-center align-center">
            <v-btn @click="search" color="primary" tile dense>
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div
        class="maldives"
        v-if="!isAdmin || (isAdmin && detail.profile.userName)"
      >
        <BaseProfile
          class="maldives-profile"
          :headImg="detail.profile.headImg"
          :pacerLevel="detail.profile.pacerLevel"
          :name="detail.profile.userName"
        />
        <BaseCountdown :endDate="endDate" />
        <v-card class="maldives-card mt-4">
          <div class="maldives-card-title">{{ $t('personalScore.title') }}</div>
          <div class="maldives-card-body chart">
            <canvas id="myChart" height="225"></canvas>
          </div>
        </v-card>
        <v-card class="maldives-card mt-4">
          <div class="maldives-card-title">{{ $t('teamScore.title') }}</div>
          <div class="maldives-card-body">
            <BaseScoreProgress
              :textLeft="$t('teamScore.left')"
              :textRight="$t('teamScore.right')"
              :maxValueLeft="130000"
              :maxValueRight="130000"
              :valueLeft="
                (detail.teamScore &&
                  detail.teamScore.summary &&
                  detail.teamScore.summary.L) ||
                  0
              "
              :valueRight="
                (detail.teamScore &&
                  detail.teamScore.summary &&
                  detail.teamScore.summary.R) ||
                  0
              "
            />
            <BaseMonthScoreTable :items="detail.teamScore.list" class="mt-4" />
          </div>
        </v-card>
        <v-card class="maldives-card mt-4">
          <div class="maldives-card-title">คะแนน G1</div>
          <div class="maldives-card-body">
            <BaseScoreProgress
              :textLeft="$t('g1Score.left')"
              :textRight="$t('g1Score.right')"
              :maxValueLeft="10000"
              :maxValueRight="10000"
              :valueLeft="(sumG1 && sumG1.L) || 0"
              :valueRight="(sumG1 && sumG1.R) || 0"
            />
            <BaseMonthScoreTable :items="formatG1Score" class="mt-4" />
          </div>
        </v-card>
      </div>
    </v-main>
  </div>
</template>

<script>
import EventServices from '@/services/EventServices'

import * as Chart from 'chart.js'
import * as dayjs from 'dayjs'

import { getShortMonthTH, getFullMonthTH } from '@/constants/monthsTH.js'
import { CHART_COLORS } from '@/constants/chartColors.js'
import { mapGetters } from 'vuex'

export default {
  name: 'EventMaldives',
  data() {
    return {
      detail: {
        ep: [],
        g1Scores: [],
        personalScores: [],
        teamScore: {
          list: [],
          summary: {
            L: 0.0,
            R: 0.0,
          },
        },
        profile: {
          headImg: null,
          pacerLevel: 'JP',
          userName: null,
        },
      },
      formatG1Score: [],
      sumG1: {
        L: 0,
        R: 0,
      },
      startDate: dayjs()
        .startOf('year')
        .toDate(),
      // december = 11
      endDate: dayjs()
        .set('month', 11)
        .endOf('month')
        .toDate(),
      chartConfig: {
        type: 'bar',
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 1500,
                  stepSize: 500,
                  fontStyle: 'bold',
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 11,
                  fontFamily: 'Lato',
                  fontColor: '#98A5BA',
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              title: () => '',
              label: tooltipItem => {
                return `${tooltipItem.value}`
              },
            },
            bodyFontStyle: 'bold',
            position: 'custom',
          },
          legend: {
            labels: {
              fontStyle: 'normal',
              fontSize: 14,
              fontColor: '#26292C',
              fontFamily: 'Helvetica',
            },
          },
        },
      },
      chartLabels: [],
      chartDatasets: [],
      legendLabel: '',
      isAdmin: false,
      searchInviteCode: null,
      rules: [
        v =>
          ((v || '').length > 0 && (v || '').length <= 7) ||
          'Invite code should be 7 character',
      ],
      loading: false,
    }
  },
  computed: {
    ...mapGetters({ getMallMemberTokenKey: 'PacerStore/MallMemberTokenKey' }),
  },
  async created() {
    try {
      /*
        Support CX to access with router /events/maldives/admin/:inviteCode to see pacer data
        NOTE: Check to access through /dashboard to pass authentication
      */
      this.isAdmin = this.$route.path.includes('/dashboard')

      if (!this.isAdmin) {
        this.loading = true
        const res = await EventServices.getEventDetail('maldives', {
          dateStart: dayjs(this.startDate).format('YYYY-MM-DD'),
          dateEnd: dayjs(this.endDate).format('YYYY-MM-DD'),
          mallMemberToken: this.getMallMemberTokenKey,
        })
        this.detail = res.data.data
        this.loading = false

        this.setLegendLabel()
        this.transformG1Score()
        await this.transformPersonalScoreToChartData()
      }
    } catch (error) {
      this.loading = false
      const response = error.response
      this.$swal
        .fire({
          icon: response.status >= 500 ? 'error' : 'warning',
          text: response.data.message || 'Internal Server Error',
        })
        .then(result => {
          result.isConfirmed && this.$router.push('/')
        })
    }
  },
  methods: {
    transformG1Score() {
      const result = {}

      this.detail.g1Scores.forEach(item => {
        if (!result[item.month]) {
          result[item.month] = {
            L: 0,
            R: 0,
          }
        }
        if (item.omcSide === 'L') {
          const oldValueL = (result[item.month] && result[item.month].L) || 0
          result[item.month].L = oldValueL + Number(item.purchaseMp)
        } else {
          const oldValueR = (result[item.month] && result[item.month]?.R) || 0
          result[item.month].R = oldValueR + Number(item.purchaseMp)
        }
      })

      this.formatG1Score = Object.keys(result)
        .map(key => {
          return {
            month: key,
            ...result[key],
          }
        })
        .sort((a, b) => {
          return Number(a.month) - Number(b.month)
        })

      this.sumG1Side('L')
      this.sumG1Side('R')
    },
    sumG1Side(side) {
      this.sumG1[side] = this.formatG1Score.reduce((prev, cur) => {
        return prev + cur[side]
      }, 0)
    },
    async transformPersonalScoreToChartData() {
      const labels = []
      const data = []
      const backgroundColor = []

      this.detail.personalScores.forEach(item => {
        labels.push(this.getMonthLabels(item.month))
        data.push(Number(item.purchaseMp))
        const bc =
          Number(item.purchaseMp) >= 1000
            ? CHART_COLORS.ACTIVE
            : CHART_COLORS.INACTIVE
        backgroundColor.push(bc)
      })

      this.chartLabels = labels
      this.chartDatasets = await [
        {
          data: data,
          borderWidth: 1,
          borderRadius: 6,
          label: this.legendLabel,
          backgroundColor: backgroundColor,
        },
      ]
      this.setChartData()
    },
    getMonthLabels(month) {
      return this.$i18n.locale.includes('en')
        ? dayjs()
            .set('month', month - 1)
            .format('MMM')
        : getShortMonthTH(month)
    },
    setLegendLabel() {
      const currentYear = dayjs(this.startDate)
      if (this.$i18n.locale.includes('en')) {
        this.legendLabel = `${currentYear.format(
          'MMMM',
        )} - ${this.endDate.format('MMMM')} ${currentYear.get('year')}}`
      } else {
        this.legendLabel = `${getFullMonthTH(1)} - ${getFullMonthTH(
          7,
        )} ${currentYear.get('year')}`
      }
    },
    setChartData() {
      // NOTE: Custom tooltip to show at x,y of cursor
      Chart.Tooltip.positioners.custom = function(_, eventPosition) {
        return {
          x: eventPosition.x,
          y: eventPosition.y,
        }
      }
      const ctx = document.getElementById('myChart')
      new Chart(ctx, {
        ...this.chartConfig,
        data: {
          labels: this.chartLabels,
          datasets: this.chartDatasets,
        },
      })
    },
    async search() {
      if (this.$refs.form.validate() && this.isAdmin) {
        try {
          this.loading = true
          const res = await EventServices.getEventDetailAdmin('maldives', {
            dateStart: dayjs(this.startDate).format('YYYY-MM-DD'),
            dateEnd: dayjs(this.endDate).format('YYYY-MM-DD'),
            inviteCode: this.searchInviteCode,
          })

          if (res.data.status !== 200) {
            this.$swal.fire({
              icon: res.data.status >= 500 ? 'error' : 'warning',
              text:
                res.data.status >= 500
                  ? 'Internal Server Error'
                  : 'Pacer not found',
            })
          } else {
            this.detail = res.data.data

            this.setLegendLabel()
            this.transformG1Score()
            await this.transformPersonalScoreToChartData()
          }
          this.loading = false
        } catch (error) {
          this.loading = false
          const response = error.response || error
          this.$swal.fire({
            icon: (response?.status || 500) >= 500 ? 'error' : 'warning',
            text: response?.data?.message || 'Internal Server Error',
          })
        }
      }
    },
  },
  mounted() {
    !this.isAdmin && this.setChartData()
  },
}
</script>

<style lang="scss" scoped>
.maldives {
  padding: 0 1rem 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: linear-gradient(white 180px, #da3d3c 0%);

  &-card {
    border-radius: 18px;
    color: black !important;
    min-width: 343px;

    &-title {
      display: flex;
      align-items: center;
      height: 3rem;
      padding: 0 1.5rem;

      font-size: 1rem;
      font-weight: bold;

      color: white;
      background: #383838;
    }

    &-body {
      display: flex;
      flex-flow: column;

      padding: 1.5rem 1.5rem;
      color: black !important;
    }
  }

  &-profile {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
  }
}

.chart {
  padding: 1.5rem 1rem !important;
}
</style>
